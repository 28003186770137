<template>
  <div class="__buy_bundle">
    <div class="is-three-by-three">
      <div class="component-loader" v-if="cardsLoading">
        <ComponentLoader :loading="cardsLoading" />
      </div>
      <voiceAmountChecker
        :rate="retailsData[0].rate"
        v-if="!cardsLoading"></voiceAmountChecker>
      <div
        class="__wallet__card__inner__container"
        v-for="retail in retailsData"
        :key="retail.id"
        :class="[retail.amount == 0 ? 'promo_active_parent' : '']">
        <WalletCard
          @choseBundle="choseBundle"
          :userSelectedData="userSelectedData"
          :cardData="retail" />
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import Storage from "@/services/storage.js";
  import WalletCard from "./walletCard.vue";
  import voiceAmountChecker from "./voiceAmountChecker.vue";
  export default {
    name: "buy-bundle",
    components: {
      WalletCard,
      voiceAmountChecker,
    },
    data() {
      return {
        userSelectedData: {},
        cardsLoading: true,
      };
    },
    methods: {
      choseBundle(val) {
        this.userSelectedData = val;
      },
    },
    computed: {
      isCorporate() {
        const storage = new Storage();
        return storage.getSavedState("isCorporate");
      },
      retailsData() {
        if (this.isCorporate) {
          return this.corporatePricing;
        }
        return this.individualPricing;
      },

      individualPricing() {
        return store.getters["wallet/getSMSVoiceRatesIndividual"];
      },
      corporatePricing() {
        return store.getters["wallet/getSMSVoiceRatesCorporate"];
      },
    },
    mounted() {
      store.dispatch("wallet/accountBalance");
      this.cardsLoading = true;
      store.dispatch("wallet/getSMSVoiceRate").finally(() => {
        this.cardsLoading = false;
      });
    },
  };
</script>

<style scoped lang="scss">
  @import url("../../../styles/wallet/__wallet_card.scss");

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #484a4f;
    text-align: center;
  }

  .is-three-by-three {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  }

  .promo_active_parent {
    place-self: center;
  }

  .__buy_bundle {
    overflow-y: scroll;
    height: 65vmin;
    padding: 0 !important;
    margin: 0 !important;
  }

  @media screen and (max-width: 820px) {
    body,
    html {
      overflow: auto;
    }

    .is-three-by-three {
      display: grid !important;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 1rem;
    }

    .__wallet__card__inner__container,
    .promo_active_parent {
      place-self: start;
    }

    .__buy_bundle {
      height: 65vh !important;
    }
  }

  .component-loader {
    display: grid;
    width: 50vw;
    place-content: center;
  }
</style>
